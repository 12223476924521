var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return [_c('button',{staticClass:"text-oDark focus:outline-none",attrs:{"aria-label":"More Actions Menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('i',{staticClass:"text-sm text-gray-600 fas fa-ellipsis-v"})])]}},{key:"default",fn:function(ref){
      var hide = ref.hide;
return [_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-md z-200",on:{"click":function($event){return hide()}}},[_c('t-dropdown-item',{staticClass:"font-medium",on:{"click":function($event){return _vm.executeAction('updateAccountStatus', {
              action: _vm.data.user_status === 'A' ? 'D' : 'A',
            })}}},[_vm._v(" "+_vm._s(_vm.data.user_status === 'A' ? _vm.$t( 'components.organizationUsersManagement.table.action.deactivate' ) : _vm.$t( 'components.organizationUsersManagement.table.action.activate' ))+" ")]),_c('t-dropdown-item',{staticClass:"font-medium",on:{"click":function($event){return _vm.executeAction('showUpdateOrResetPassword', { action: 'change' })}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.table.action.changePassword' ))+" ")]),_c('t-dropdown-item',{staticClass:"font-medium",on:{"click":function($event){return _vm.executeAction('showUpdateOrResetPassword', { action: 'reset' })}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.table.action.resetPassword' ))+" ")]),(!_vm.data.is_active)?_c('t-dropdown-item',{staticClass:"font-medium",on:{"click":function($event){return _vm.executeAction('updateVerifyStatus', { action: 'verify' })}}},[_vm._v(" "+_vm._s(_vm.$t('components.organizationUsersManagement.table.action.verify'))+" ")]):_vm._e(),(_vm.$user.is_superadmin)?_c('t-dropdown-item',{staticClass:"font-medium",on:{"click":function($event){return _vm.executeAction('forceLogout')}}},[_vm._v(" "+_vm._s(_vm.$t( 'components.organizationUsersManagement.table.action.forceLogout' ))+" ")]):_vm._e()],1)]}}])}),_c('t-modal',{attrs:{"name":("holdOrDeactivateOrgUserAccount-" + _vm.primaryKey),"variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true,"escToClose":!_vm.holdOrDeactivate.isLoading,"clickToClose":!_vm.holdOrDeactivate.isLoading}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.holdOrDeactivate.action === 'D' ? _vm.$t( 'components.organizationUsersManagement.modal.holdDeactivate.deactivateAccount' ) : _vm.$t( 'components.organizationUsersManagement.modal.holdDeactivate.holdAccount' ))+" ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(
              _vm.executeAction('submitHoldOrDeactivateOrgUserAccount', {
                action: _vm.holdOrDeactivate.action,
              })
            )}}},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.organizationUsersManagement.modal.holdDeactivate.reason.title'
              ),"label":_vm.$t(
                'components.organizationUsersManagement.modal.holdDeactivate.reason.title'
              ),"placeholder":_vm.$t(
                'components.organizationUsersManagement.modal.holdDeactivate.reason.placeholder'
              ),"options":_vm.holdOrDeactivate.action === 'H'
                ? _vm.holdOrDeactivate.reasons.hold
                : _vm.holdOrDeactivate.reasons.deactivate,"hide-search-box":""},model:{value:(_vm.holdOrDeactivate.models.reason),callback:function ($$v) {_vm.$set(_vm.holdOrDeactivate.models, "reason", $$v)},expression:"holdOrDeactivate.models.reason"}}),_c('AppInput',{attrs:{"rules":"","label":_vm.$t(
                'components.organizationUsersManagement.modal.holdDeactivate.remark'
              ),"type":"textarea","name":_vm.$t(
                'components.organizationUsersManagement.modal.holdDeactivate.remark'
              )},model:{value:(_vm.holdOrDeactivate.models.remark),callback:function ($$v) {_vm.$set(_vm.holdOrDeactivate.models, "remark", $$v)},expression:"holdOrDeactivate.models.remark"}}),_c('button',{ref:"holdOrDeactivateSubmitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})],1),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.executeAction('hideHoldOrDeactivateOrgUserAccount')}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","isLoading":_vm.holdOrDeactivate.isLoading},on:{"click":function($event){return _vm.$refs.holdOrDeactivateSubmitButtonRef.click()}}},[_vm._v(" "+_vm._s(_vm.holdOrDeactivate.action === 'D' ? _vm.$t( 'components.organizationUsersManagement.modal.holdDeactivate.deactivate' ) : _vm.$t( 'components.organizationUsersManagement.modal.holdDeactivate.hold' ))+" ")])],1)]),_c('t-modal',{ref:"popup",attrs:{"name":("updateOrResetPassword-" + _vm.primaryKey),"variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true,"escToClose":!_vm.isLoading,"clickToClose":!_vm.isLoading}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.password.action === 'change' ? 'Change' : 'Reset')+" password ")]),(_vm.password.action === 'change')?_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(
              _vm.executeAction('doUpdateOrResetPassword', {
                action: _vm.password.action,
              })
            )}}},[_c('AppInput',{attrs:{"rules":"required|min:6","label":"Current Password","type":"password","name":"old"},model:{value:(_vm.password.models.old),callback:function ($$v) {_vm.$set(_vm.password.models, "old", $$v)},expression:"password.models.old"}}),_c('AppInput',{attrs:{"rules":"required|min:6","label":"New Password","type":"password","name":"password"},model:{value:(_vm.password.models.new),callback:function ($$v) {_vm.$set(_vm.password.models, "new", $$v)},expression:"password.models.new"}}),_c('AppInput',{attrs:{"rules":"confirmed:password","label":"Confirm New Password","type":"password","name":"confirm"},model:{value:(_vm.password.models.confirm),callback:function ($$v) {_vm.$set(_vm.password.models, "confirm", $$v)},expression:"password.models.confirm"}}),_c('button',{ref:"passwordButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}],null,false,3512375877)})],1):_vm._e(),(_vm.password.action === 'reset')?_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(
              _vm.executeAction('doUpdateOrResetPassword', {
                action: _vm.password.action,
              })
            )}}},[_c('AppInput',{attrs:{"rules":"required|email","label":"Email address","type":"email","name":"email address"},model:{value:(_vm.password.models.email),callback:function ($$v) {_vm.$set(_vm.password.models, "email", $$v)},expression:"password.models.email"}}),_c('button',{ref:"passwordButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}],null,false,1756016498)})],1):_vm._e(),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.executeAction('hideUpdateOrResetPassword')}}},[_vm._v(" Cancel ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.passwordButton.click()}}},[_vm._v(" "+_vm._s(_vm.password.action === 'change' ? 'Change' : 'Reset')+" ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }